import { Auth } from 'aws-amplify';
import qs from 'qs';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import { FormProps } from '../../forms/types';
import { useForm } from '../../forms/useForm';
import { eraseErrorsOnFocus } from '../../forms/utils';
import { LoginBox } from './components/LoginBox';
import { PageContainer } from '../components/PageContainer';
import { InputWithLabel } from '../../components/InputWithLabel/InputWithLabel';
import { Button } from '../../components/Button/Button';
import { Box } from '../../components/Box/Box';
import { HelpPopup } from './components/HelpPopup';
import { useAccept } from '../TermsOfService/terms-of-service-api';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';
import { Checkbox } from '../../components/Checkbox';
import { useShowErrorToast } from '../../hooks/useShowErrorToast';
import loginStyles from './components/Login.pcss';
import loginBoxStyles from './components/LoginBox.pcss';
import styles from '../../conversations/options/ConversationOptionsModal.pcss';
import TermsOfServiceInformation from './components/TermsOfServiceInformation';
import { passwordPolicyValidationRegexp } from '../../utils/passwordPolicy';

const initialFields = {
  email: {
    name: 'email',
    labelId: 'auth.signIn.email',
  },
  newPassword: {
    name: 'newPassword',
    labelId: 'auth.signIn.password',
  },
  repeatNewPassword: {
    name: 'repeatNewPassword',
    labelId: 'auth.resetPassword.repeatPassword',
  },
  acceptTermsOfService: {
    name: 'acceptTermsOfService',
  },
};

export const Register: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const { mutateAsync: acceptTermsOfService, isError: isAcceptError } =
    useAccept();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().matches(
      passwordPolicyValidationRegexp,
      'auth.errors.complexity'
    ),
    repeatNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'auth.errors.repeat'
    ),
  });

  useShowErrorToast(isAcceptError);

  let formProps: FormProps = useForm({
    initialFields,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const user = await Auth.signIn(
          query.username as string,
          query.code as string
        );
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, values.newPassword, {});
          await acceptTermsOfService();
          history.push('/');
        }
      } catch (error) {
        if (
          error.message ===
          'Temporary password has expired and must be reset by an administrator.'
        ) {
          history.push('/login?activationLinkExpired=true');
        } else if (error.code === 'NotAuthorizedException') {
          history.push('/login?activationLinkAlreadyUsed=true');
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const termsOfServiceAccepted = Boolean(
    formProps.fieldsProps.acceptTermsOfService.value
  );

  const Popup = () => (
    <HelpPopup messageId={'auth.goBack.signIn.setNewPasswordHelp'} />
  );

  formProps = eraseErrorsOnFocus(formProps);
  return (
    <PageContainer>
      <LoginBox
        className={loginBoxStyles.tallBox}
        text={intl.messages['auth.signIn.registration'] as string}
        header={intl.messages['auth.signIn.registrationTitle'] as string}
      >
        <Box noPadding>
          <form onSubmit={formProps.onFormSubmit}>
            <InputWithLabel
              // use username instead of email
              {...formProps.fieldsProps.email}
              className={loginStyles.loginInput}
              disabled
              value={query.username as string}
              dataTest="email"
            />
            <InputWithLabel
              className={loginStyles.loginInput}
              iconClass={loginStyles.helpPopupIcon}
              errorClass={loginStyles.newPasswordError}
              type="password"
              Icon={Popup}
              {...formProps.fieldsProps.newPassword}
            />
            <InputWithLabel
              className={loginStyles.loginInput}
              type="password"
              {...formProps.fieldsProps.repeatNewPassword}
            />
            <label
              htmlFor="termsOfService"
              className={loginStyles.checkboxContainer}
            >
              <div>
                <Checkbox
                  id="termsOfService"
                  data-test="terms-of-service-checkbox"
                  checked={termsOfServiceAccepted}
                  onClick={() =>
                    formProps.setFieldValue(
                      'acceptTermsOfService',
                      !termsOfServiceAccepted
                    )
                  }
                />
              </div>
              <TermsOfServiceInformation
                className={styles.label}
                translationKey={'auth.signIn.termsOfServiceCheckbox'}
                termsOfServiceTranslationKey={'auth.signIn.termsOfService'}
              />
            </label>
            <div>
              <TooltipComponent
                tooltipText={`${intl.messages['auth.signIn.registerTooltip']}`}
                tooltipPlacement="bottom-start"
                hidden={termsOfServiceAccepted}
              >
                <Button
                  className={loginStyles.registerButton}
                  buttonType={formProps.isSubmitting ? 'loading' : 'primary'}
                  dataTest="register"
                  disabled={
                    !(
                      Boolean(formProps.fieldsProps.newPassword.value) &&
                      Boolean(formProps.fieldsProps.repeatNewPassword.value)
                    ) ||
                    formProps.hasErrors ||
                    !termsOfServiceAccepted
                  }
                  type="submit"
                >
                  {!formProps.isSubmitting && (
                    <FormattedMessage id="auth.signIn.register" />
                  )}
                </Button>
              </TooltipComponent>
            </div>
          </form>
        </Box>
      </LoginBox>
    </PageContainer>
  );
};
